import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { NavigationItem } from 'src/app/components/navigation/navigation.model';
import { NavigationService } from 'src/app/core/navigation/navigation.service';
import { SidebarComponent } from '../../common/sidebar/sidebar.component';
import { defaultNavigation } from 'src/app/core/navigation/navigation';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'admin-layout',
  standalone: true,
  imports: [
    NgIf,
    MatButtonModule,
    MatIconModule,
    RouterOutlet,
    SidebarComponent,
    TranslateModule
  ],
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminLayoutComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  navigation!: NavigationItem[];

  constructor(private _navigationService: NavigationService) {}

  ngOnInit(): void {
    // Set navigation
    this._navigationService.navigation = defaultNavigation;

    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: NavigationItem[]) => {
        this.navigation = navigation;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
