<div
  class="w-full sm:w-20 sm:flex flex-row sm:flex-col h-full py-4 items-center bg-grey-800 sm:overflow-hidden"
>
  <!-- Navigation -->
  <div class="flex-grow w-full overflow-auto">
    <div class="h-full flex flex-row sm:flex-col px-2">
      <div class="text-white">
        <button
          [disabled]="businessUnits.length <= 0"
          (click)="show = !show"
          class="flex flex-col gap-2 justify-center items-center py-4 px-2 w-full bg-grey-600 mb-2 rounded-md text-center transition"
          [matTooltip]="
            selectedBusinessUnit?.displayName ||
            selectedBusinessUnit?.name ||
            ''
          "
          [matTooltipPosition]="'left'"
          [ngClass]="[
            !checkDisabled()
              ? 'cursor-pointer opacity-100 hover:bg-grey-500'
              : '!cursor-not-allowed opacity-60',
            !checkDisabled() && businessUnitModel != 0
              ? 'text-warn'
              : 'text-white'
          ]"
        >
          <mat-icon [svgIcon]="'design:store'"></mat-icon>
          <div
            *ngIf="selectedBusinessUnit"
            class="uppercase truncate w-full text-xs text-white"
          >
            {{ selectedBusinessUnit.displayName || selectedBusinessUnit.name }}
          </div>
        </button>
      </div>
      <div
        *ngFor="let item of navigation"
        [ngClass]="item.classes?.wrapper"
        class="mb-2 flex flex-col overflow-hidden {{
          item.children!.length >= 2 ? 'rounded-md' : 'rounded-lg'
        }}"
      >
        <a
          *ngFor="let child of item.children; let i = index"
          class="relative flex items-center flex-col justify-center transition px-2 py-4 text-white bg-grey-600"
          [ngClass]="
            (!child.disabled && !checkDisabled()) || child.id == 'setting'
              ? 'cursor-pointer opacity-100 hover:bg-grey-500'
              : '!cursor-not-allowed opacity-60'
          "
          [routerLink]="
            child.id == 'setting'
              ? child.link
              : (!child.disabled && !checkDisabled() && child.link) || null
          "
          [matTooltip]="
            (!child.disabled && !checkDisabled() && child.title) || ''
              | translate
          "
          [matTooltipPosition]="'left'"
          [queryParamsHandling]="'preserve'"
        >
          <mat-icon
            [routerLinkActive]="'text-warn'"
            [svgIcon]="child.icon ?? ''"
            [ngClass]="child.classes?.icon"
          ></mat-icon>
        </a>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <div class="mt-auto px-2 pt-3">
    <a
      class="relative flex-shrink-0 cursor-pointer"
      routerLink="/profile"
      [queryParamsHandling]="'preserve'"
    >
      <img
        src="assets/images/user.png"
        alt=""
        class="w-14 h-14 rounded-full overflow-hidden border-2 border-solid border-warn"
      />
    </a>
  </div>
</div>

<!--mobile-->
<!-- <div
  class="w-full sm:w-20 sm:hidden flex flex-row sm:flex-col h-full py-3 items-center bg-grey-800 sm:overflow-hidden"
>

  <div class="flex-grow w-full overflow-auto">
    <div class="h-full justify-between flex flex-row sm:flex-col px-3">
      <div
        *ngFor="let item of navigation"
        class="flex justify-around"
        [ngClass]="item.id == 'group-2' ? 'w-full gap-x-3' : ''"
      >
        <ng-container *ngIf="item.id == 'group-2'">
          <div
            *ngFor="let child of item.children; let i = index"
            class="relative flex items-center flex-col justify-center bg-grey-600 rounded-md h-full max-w-[50px] max-h-[50px] {{
              child.id !== 'client' ? 'flex-1' : 'hidden'
            }}"
          >
            <ng-container>
              <a
                [ngClass]="
                  (!child.disabled && !checkDisabled()) || child.id == 'setting'
                    ? 'pointer-events-auto opacity-100'
                    : 'pointer-events-none opacity-60'
                "
                [routerLink]="
                  child.id == 'setting'
                    ? child.link
                    : (!child.disabled && !checkDisabled() && child.link) ||
                      null
                "
                [matTooltip]="
                  (!child.disabled && !checkDisabled() && child.title) || ''
                    | translate
                "
                class="text-white flex justify-center items-center p-2 cursor-pointer"
                [routerLinkActive]="'!text-warn'"
                [matTooltipPosition]="'left'"
                [queryParamsHandling]="'preserve'"
              >
                <mat-icon
                  [svgIcon]="child.icon ?? ''"
                  class="icon-size-4"
                ></mat-icon>
              </a>
            </ng-container>
          </div>
          <div
            class="relative flex items-center flex-col justify-center bg-grey-600 rounded-md flex-1 max-w-[50px] max-h-[50px]"
          >
            <a
              (click)="isShowMenu = true"
              class="text-white flex justify-center items-center p-4 cursor-pointer"
            >
              <mat-icon
                [svgIcon]="'mat_outline:more_horiz'"
                class="icon-size-4"
              ></mat-icon>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div
  class="fixed w-full h-full top-0 bg-white p-4"
  *ngIf="isShowMenu"
  style="z-index: 1000"
>
  <div>
    <div class="flex gap-x-8">
      <button
        [disabled]="businessUnits && businessUnits.length <= 1"
        (click)="show = !show"
        class="text-white flex flex-col gap-1 justify-center items-center p-2 w-full bg-grey-600 overflow-hidden rounded text-center cursor-pointer"
      >
        <mat-icon [svgIcon]="'design:store'" class="icon-size-5"></mat-icon>
        <div
          *ngIf="selectedBusinessUnit"
          class="uppercase whitespace-nowrap overflow-ellipsis overflow-hidden w-full text-xs"
        >
          {{ selectedBusinessUnit.name }}
        </div>
      </button>
      <div class="w-full text-right">
        <button class="ml-auto btn btn--icon" (click)="isShowMenu = false">
          <mat-icon [svgIcon]="'mat_outline:close'"> </mat-icon>
        </button>
      </div>
    </div>
    <div class="flex flex-wrap mt-3 gap-3">
      <div
        *ngFor="let item of mobileNav"
        class="{{ !item.disabled ? 'w-[calc(100%/2-0.75rem)]' : 'hidden' }}"
      >
        <a
          *ngIf="!item.disabled"
          [routerLink]="item.link"
          class="inline-flex items-center p-4 pl-8 cursor-pointer border border-solid rounded w-full gap-x-3"
          [routerLinkActive]="'!text-warn'"
          [queryParamsHandling]="'preserve'"
          (click)="isShowMenu = false"
        >
          <mat-icon [svgIcon]="item.icon ?? ''"></mat-icon>
          {{ item.title ?? "" | translate }}
        </a>
      </div>
    </div>
  </div>
</div> -->

<div
  *ngIf="show"
  [@fadeIn]="show"
  [@fadeOut]="!show"
  class="absolute w-full h-full top-0 left-0 bg-black bg-opacity-50 z-[1111]"
  (click)="show = false"
>
  <div
    (click)="$event.stopPropagation()"
    class="absolute top-0 right-20 w-64 bg-surface h-full p-6 overflow-auto"
  >
    <div class="flex gap-6">
      <div class="flex flex-col flex-grow flex-shrink-0">
        <div class="text-base text-darkgreen uppercase font-bold mb-4">
          {{ "BusinessUnit.Title" | translate }}
        </div>
        <div class="flex flex-col -ml-3">
          <mat-radio-group
            class="flex flex-col"
            name="businessUnit"
            [(ngModel)]="businessUnitModel"
            (ngModelChange)="onBusinessUnitChange($event)"
          >
            <mat-radio-button
              *ngFor="let item of businessUnits"
              [value]="item.id"
            >
              {{ item.displayName || item.name }}</mat-radio-button
            >
          </mat-radio-group>
        </div>
      </div>
    </div>
  </div>
</div>
