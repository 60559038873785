import {
  HttpErrorResponse,
  HttpEvent,
  HttpInterceptorFn,
} from '@angular/common/http';
import { Logger } from '../../logger/logger';
import { Observable, catchError, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { ToastService } from 'src/app/components/toast/toast.service';
import { ApiEndpoint } from '../api';
import { TranslateService } from '@ngx-translate/core';

const log = new Logger('ErrorHandlerInterceptor');

export const errorHandlerInterceptor: HttpInterceptorFn = (req, next) => {
  const toastService = inject(ToastService);
  const translateService = inject(TranslateService);
  return next(req).pipe(
    catchError((err) => errorHandler(err, toastService, translateService))
  );
};

// Customize the default error handler here if needed
function errorHandler(
  err: HttpErrorResponse,
  toastService: ToastService,
  translateService: TranslateService
): Observable<HttpEvent<any>> {
  if (err.status === 0) {
    // A client-side or network occurred. Handle it accordingly.
    log.error('A client-side or network error occurred.');
  } else if ([401, 403].includes(err.status)) {
    // Do something with authentication error
    log.error('Authentication error.');
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    // Do something with the error
    log.error('Request error', err);
  }

  if (!err.url?.includes(ApiEndpoint.RefreshToken)) {
    toastService.open({
      message: err.error.Message ?? 'Something went wrong',
      configs: {
        payload: {
          type: 'error',
        },
      },
    });
  } else {
    toastService.open({
      message: translateService.instant('Auth.Errors.LoginAgain'),
      configs: {
        payload: {
          type: 'warning',
        },
      },
    });
  }

  // Return an observable with a user-facing error message.
  return throwError(() => ({
    status: err.status,
    statusText: err.statusText,
    message: err.message,
  }));
}
